import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Application from './Application';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Link} from 'react-router-dom';
import './i18n/config';
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";

const DatenschutzLink = styled(Link)`
  margin-right: 6px;
  margin-left: 6px;
  color: #7e8c96 !important;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  &:hover {
    color: #7e8c96;
  }
`;


ReactDOM.render((
    <BrowserRouter>
        <Application/>
        <span>
            <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 12, mb: 10}}
                        sx={{
                            position: "fixed",
                            bottom: "0",
                            width: "100%",
                            height: "1.5rem",
                        }}
            >
				<DatenschutzLink
                    to="/AGB.pdf"
                    target="_blank">
                    AGB
                </DatenschutzLink>
                <DatenschutzLink
                    to="/Datenschutz_CCC_v2_am_24082022.pdf"
                    target="_blank">
                    Datenschutzbestimmungen
                </DatenschutzLink>
                <DatenschutzLink
                    to="/impressum"
                    target="_blank">
                    Impressum
                </DatenschutzLink>
            </Typography>
        </span>
    </BrowserRouter>
), document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
